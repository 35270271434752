/* Estilos para o react-burger-menu */
.bm-burger-button {
    position: absolute;
    width: 36px;
    height: 30px;
    right: 36px;
    top: 36px;
}

.bm-burger-bars {
    background: #373a47;
}

.bm-cross-button {
    height: 24px;
    width: 24px;
}

.bm-cross {
    background: #bdc3c7;
}

.bm-menu-wrap {
    position: fixed;
    height: 100%;
}

.bm-menu {
    background: #fff;
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
}

.bm-item-list {
    color: #373a47;
    padding: 0.8em;
}

.bm-item {
    display: flex;
    align-items: center;
    padding: 10px 0;
    color: black;
    text-decoration: none;
}

.bm-overlay {
    background: rgba(0, 0, 0, 0.3);
}


.css-1edfpdg-MuiTypography-root {
    font-family: 'Ubuntu', sans-serif;
    font-weight: 400;
}