.containerEspecializacao {
    display: flex;
    justify-content: center;
    margin: 3% 10%;
    align-items: center;
    margin-bottom: 5%;
}

.blocoTextoEspecializacao {
    width: 45%;
    margin-right: 5%;
}

.blocoTextoEspecializacao h1 {
    font-size: 36px;
    line-height: 47px;
    color: #E80088;
    font-family: 'Codec Cold Trial', sans-serif;
    font-weight: 900;
}

.blocoTextoEspecializacao p,
.listaEspecializacaoItem {
    font-size: 18px;
    font-family: "Ubuntu", sans-serif;
    line-height: 30px;
}

.bannerEspecializacao {
    width: 45%;
    margin-left: 5%;
}

.listaEspecializacaoItem {
    margin-left: 3%;
}

@media (max-width: 768px) {
    .containerEspecializacao {
        flex-direction: column;
        margin-top: 10%
    }

    .blocoTextoEspecializacao,
    .bannerEspecializacao {
        width: 90%;
    }
}