.containerContato {
  display: flex;
  justify-content: space-around;
  padding: 5%;
}

.box1contato {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.titulo_contato {
  font-size: 36px;
  line-height: 47px;
  color: #e80088;
  font-family: 'Codec Cold Trial', sans-serif;
  font-weight: 900;
}

.item_contato {
  font-size: 18px;
  font-family: 'Ubuntu', sans-serif;
  line-height: 30px;
}

.contatosLink {
  font-family: 'Ubuntu', sans-serif;
  color: #282828;
  padding-bottom: 10px;
  position: relative;
  display: inline-block;
}

.contatosLink::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  background-color: rgb(232, 0, 136);
  transform: scaleX(0);
  /* Inicialmente invisível */
  transform-origin: left;
  /* Começar a animação da esquerda */
  transition: transform 0.3s ease-in-out;
  /* Animação suave */
}

.contatosLink:hover::after {
  transform: scaleX(1);
  /* Expande da esquerda para a direita */
}


.box2contato {
  box-shadow: 14px 14px 20px 5px #e0e0e0;
  border: 2px solid #cfcfcf;
}

.contato_button {
  color: white;
  background-color: #e80088;
  border: none;
  width: 60%;
  padding: 5%;
  border-radius: 4px;
  cursor: pointer;
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* Fundo escurecido */
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 400px;
  /* Ajuste o tamanho conforme necessário */
  position: relative;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);

  h2 {
    margin-bottom: 5%;
  }
}

.close {
  position: absolute;
  top: 10px;
  right: 15px;
  font-size: 24px;
  font-weight: bold;
  color: #333;
  cursor: pointer;
}

.close:hover {
  color: #e80088;
  /* Cor ao passar o mouse */
}

.form-group {
  margin-bottom: 15px;
  margin-right: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
}

.form-group input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.submit_button {
  color: white;
  background-color: #e80088;
  border: none;
  padding: 1% 2%;
  border-radius: 4px;
  cursor: pointer;
}

.submit_button,
.contato_button {
  transition: background-color 0.3s ease, color 0.3s ease;
}

.submit_button:hover,
.contato_button:hover {
  background-color: #c70070;
}


/* Estilo para a mensagem de termos */
.terms-message {
  margin-top: 15px;
  font-size: 12px;
  color: #666;
  text-align: center;
}

.terms-message .terms-text {
  font-size: 12px;
  line-height: 1.5;
}

.terms-message a {
  color: #e80088;
  text-decoration: none;
  font-weight: bold;
}

.terms-message a:hover {
  text-decoration: underline;
}

.checkbox-container {
  margin-top: 10px;
}

.checkbox-container input[type="checkbox"] {
  transform: scale(1.2);
  margin-right: 5px;
}

.checkbox-container label {
  font-family: "Ubuntu", sans-serif;
  font-size: 12px;
  color: #666;
  margin: 0;
}

.form-group label {
  font-family: "Ubuntu", sans-serif;
}

@media (max-width: 768px) {

  .containerContato {
    display: flex;
    flex-direction: column;
    max-width: 100vw;
  }

  .box1contato {
    width: 90%;
    box-sizing: border-box;
  }

  .box2contato {
    box-sizing: border-box;
    width: 100%;
    height: 300px;
    justify-content: center;
    margin: 5% 0;
  }

  .mapa_contato {
    width: 100%;
    height: 300px;
  }

  .contato_button {
    margin: 10% 0;
    position: relative;
    left: 25%;
  }

  .submit_button {
    width: 35%;
    padding: 5% 0;
    transform: translate(+85%);
  }
}

@media only screen and (min-width: 330px) and (max-width: 959px) {

  .containerContato {
    margin-top: 12%;

  }
}