.parceiros_container {
    display: flex;
    flex-direction: column;
    margin: 0 10%;


}

.parceirosBloco1 {
    margin: 5%;
}

.parceirosBloco1 h1 {
    font-size: 36px;
    line-height: 47px;
    color: #E80088;
    font-family: 'Codec Cold Trial', sans-serif;
    font-weight: 900;
}

.parceirosBloco1 p {
    font-size: 18px;
    font-family: 'Ubuntu', sans-serif;
    font-weight: 400;
    line-height: 30px;
}

.parceirosBloco2 {
    display: flex;
    flex-wrap: wrap;
    margin: 0 10%;
    justify-content: center;
}

.imagemLogoParceiros {
    margin: 5% 5%;
}

@media only screen and (min-width: 330px) and (max-width: 959px) {

    .parceiros_container {
        margin-top: 12%;
        margin-bottom: 10%;
    }

}