.loading {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 30%;
    right: 45%;
    opacity: 0; /* Inicialmente invisível */
    transition: opacity 1s ease-in-out; /* Transição suave para o fade */
    z-index: 9999; /* Garanta que o carregamento fique acima dos outros elementos */
  }
  
  .loading.show {
    opacity: 1; /* Torna o elemento visível */
  }
  
  .loading-p {
    font-size: 50px;
  }
  