.containerMundoAgenda {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 3%;
}

.box1mundoAgenda {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 35%;
    margin-bottom: 5%;
}

.box1mundoAgenda h1 {
    color: #E80088;
    font-size: 36px;
    font-family: 'Codec Cold Trial', sans-serif;
    font-weight: 900;
    line-height: 30px;
}

.box1mundoAgenda h2 {
    color: #282828;
    font-size: 24px;
    font-family: 'Ubuntu', sans-serif;
    font-weight: 600;
    line-height: 30px;
}

.box1mundoAgenda p {
    font-size: 18px;
    font-family: 'Ubuntu', sans-serif;
    margin-bottom: 15px;
    line-height: 30px;
    text-align: justify;
    color: #282828;
    font-weight: 400;
}

.box2mundoAgenda {
    width: 35%;
    margin-left: 5%;
    margin-bottom: 5%;
}

.imagemMundoAgenda {
    width: 100%;
}

.mundoAgendaLink {
    color: white;
    font-size: 16px;
    font-family: 'Ubuntu', sans-serif;
    background-color: #E80088;
    width: 40%;
    padding: 15px 40px;
    border-radius: 4px;
    text-align: center;
    font-weight: 400;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.mundoAgendaLink:hover {
    color: white;
    background-color: #ff50b6;
}

.icon {
    margin-left: 7%;
}

@media (max-width: 768px) {

    .containerMundoAgenda {
        flex-direction: column-reverse;
    }

    .box1mundoAgenda {
        width: 80%;
        justify-content: center;

    }

    .box2mundoAgenda {
        width: 70%;
        margin: 0 auto 10% auto;
    }

    .mundoAgendaLink {
        border-radius: 10px;
        padding: 10px 10px;
        margin: 10% auto;
    }
}

@media only screen and (min-width: 330px) and (max-width: 959px) {

    .containerMundoAgenda {
        margin-top: 12%;

    }
}