/* Estilos gerais do cabeçalho */
.cabecalho {
    width: 100%;
    display: flex;
    flex: 1;
    align-items: center;
    color: black;
    justify-content: space-evenly;
    border-bottom: solid 1px#ededed;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);

}

.cabecalho-lista {
    display: flex;
    align-items: center;
    position: relative;
    gap: 120px;
}

.cabecalho-lista-item {
    list-style-type: none;
    font-size: 14px;
    white-space: nowrap;
    position: relative;
    font-family: 'Ubuntu', sans-serif;
    font-weight: 700;

}

/* Estilos para links dos itens da lista */
.cabecalho-lista-item-link {
    text-decoration: none;
    color: black;
}

.cabecalho-lista-item-link:hover {
    color: #E80088;
}

/* Estilos para o dropdown principal */
.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 200px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 9999;
    list-style-type: none;
    padding: 0;
    margin: 0;
}

/* Estilos para os itens dentro do dropdown */
.dropdown-content li {
    display: block;
}

.dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

.dropdown-content a:hover {
    background-color: #ddd;
}

/* Mostrar o dropdown ao passar o mouse */
.cabecalho-lista-item.dropdown:hover .dropdown-content {
    display: block;
}

/* Estilos para o sub-dropdowns (expertizeContent e mundofazContent) */
.expertizeContent,
.mundofazContent {
    display: none;
    position: absolute;
    left: 100%;
    top: 0;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 10000;
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.expertizeContent li,
.mundofazContent li {
    display: block;
}

.expertizeContent a,
.mundofazContent a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

.expertizeContent a:hover,
.mundofazContent a:hover {
    background-color: #ddd;
}

/* Mostrar o sub-dropdown ao passar o mouse */
.cabecalho-lista-item .expertize:hover .expertizeContent,
.cabecalho-lista-item .mundofaz:hover .mundofazContent {
    display: block;
}

/* Ajustar a cor do item ao passar o mouse */
.cabecalho-lista-item:hover {
    color: #E80088;
}

.logo-mld {
    margin-left: 15%;
    margin-top: 6%;
    margin-bottom: 6%;
    width: 50%;
}

/* Estilos para o menu hambúrguer */
.menu-hamburguer {
    display: none;
    /* Ocultar por padrão */
    flex-direction: column;
    cursor: pointer;
}

.menu-hamburguer .linha {
    height: 3px;
    width: 25px;
    background-color: #000;
    margin: 3px 0;
}

/* Estilo da barra lateral */
.sidebar {
    position: fixed;
    top: 0;
    left: -250px;
    /* Começa fora da tela */
    width: 250px;
    height: 100%;
    background-color: white;
    transition: left 0.3s ease;
    z-index: 1000;
}

.sidebar.open {
    left: 0;
    /* Move para a esquerda quando aberto */
}

.sidebar-lista {
    list-style: none;
    padding: 20px;
}

@media only screen and (min-width:960px) and (max-width: 1280px) {
    .cabecalho-lista {
        gap: 30px;
    }
}

/* Exibir menu hambúrguer em telas pequenas */
@media (max-width: 768px) {

    .cabecalho-lista {
        display: none;
        /* Ocultar a lista padrão */
    }

    .menu-hamburguer {
        display: flex;
        /* Mostrar o menu hambúrguer */
        margin-right: 2%;
    }

    .logo-mld {
        width: 40%;
        margin-left: 2%;
    }

    .overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        /* Cor escura com opacidade */
        z-index: 999;
        /* Abaixo da sidebar, mas acima do conteúdo */
    }


    .overlay.active {
        display: block;
        /* Exibe quando o menu está aberto */
    }

}