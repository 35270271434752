.carrossel {
    position: relative;
    width: 100%;
    max-width: 100%;
    height: 400px;
    /* Pode ser ajustado se necessário */
    margin: 0 auto;
    overflow: hidden;
}

.carrossel-inner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    position: relative;
}

.slide {
    position: absolute;
    opacity: 0;
    transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
    text-align: center;
    padding: 100px;
    width: 70%;
    top: 50%;
    transform: translateY(-50%) translateX(100%);
    /* Inicia fora da tela à direita */
    visibility: hidden;
}

.tituloSlideHumanos {
    color: #e80088;
    font-family: 'Codec Cold Trial', sans-serif;
    font-weight: 600;
    line-height: 30px;
    margin-bottom: 20px;
    text-align: center;
    font-size: 24px;
    margin-bottom: 5%;
}

.textoSlideHumanos {
    font-size: 18px;
    font-family: 'Ubuntu', sans-serif;
    line-height: 30px;
    text-align: center;
    color: #282828;
    font-weight: 400;
}

.slide.ativo {
    opacity: 1;
    visibility: visible;
    z-index: 2;
    transform: translateY(-50%) translateX(0);
    /* O slide ativo aparece no centro */
}

.slide.anterior {
    opacity: 0.5;
    visibility: visible;
    z-index: 1;
    transform: translateY(-50%) translateX(-100%);
    /* Slide anterior vai para a esquerda */
}

.slide.proximo {
    opacity: 0.5;
    visibility: visible;
    z-index: 1;
    transform: translateY(-50%) translateX(100%);
    /* Slide próximo vai para a direita */
}

.pause-btn {
    position: absolute;
    bottom: 30px;
    /* Posição padrão para telas maiores */
    right: 50%;
    transform: translateX(50%);
    padding: 10px 15px;
    background-color: #e80088;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    z-index: 9999;
}

.pause-btn::before {
    content: '||';
    /* Ícone de pause inicial */
    font-size: 15px;
}

.pause-btn.playing::before {
    content: '▶';
    /* Ícone de play quando o carrossel está pausado */
}

.pause-btn:hover {
    background-color: #c6006f;
}

@media (max-width: 768px) {
    .carrossel {
        height: 500px;
        /* Reduz a altura do carrossel em telas menores */
    }

    .slide {
        padding: 50px;
        /* Reduz o padding para slides em telas menores */
        width: 90%;
        /* Aumenta a largura dos slides para ocupar mais espaço */
    }

    .tituloSlideHumanos {
        font-size: 20px;
        /* Reduz o tamanho da fonte do título */
    }

    .textoSlideHumanos {
        font-size: 16px;
        /* Reduz o tamanho da fonte do texto */
        line-height: 24px;
        /* Ajusta o espaçamento entre linhas */
    }

    .pause-btn {
        padding: 8px 12px;
        /* Ajusta o tamanho do botão em telas menores */
        bottom: 20px;
        /* Muda a posição do botão */
        z-index: auto;
    }
}