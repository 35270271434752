.sectionMundoDispara {
  display: flex;
  flex-direction: column;
}

.boxMundoDispara1 {
  display: flex;
  flex-direction: column;
  width: 25%;
}

.titulo_mundo_disp {
  color: #e80088;
  font-size: 36px;
  font-family: 'Codec Cold Trial', sans-serif;
  font-weight: 900;
  line-height: 30px;
  margin-bottom: 20px;
}

.paragrafo_mundo_disp {
  font-size: 18px;
  font-family: 'Ubuntu', sans-serif;
  line-height: 30px;
  text-align: justify;
  color: #282828;
  font-weight: 400;
}

.boxMundoDispara2 {
  width: 40%;
  justify-content: center;
  text-align: end;
}

.imagemMundoDispara {
  width: 80%;
}

.upperBox {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 5%;
  border-bottom: solid #e80088;
  margin: 3% auto;
}

.boxMundoDispara3 {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.box_sub_box {
  display: flex;
  justify-content: center;
}

.sub_box_mundoDispara_1,
.sub_box_mundoDispara_2,
.sub_box_mundoDispara_3,
.sub_box_mundoDispara_4 {
  width: 25%;
  margin: 7% 7% 1% 7%;
  text-align: justify;
  transition: transform 0.3s ease-in-out, background-color 0.3s ease-in-out;
}

.titulo_subbox,
.icon {
  text-align: center;
  color: #e80088;
  font-size: 36px;
  font-family: 'Codec Cold Trial', sans-serif;
  font-weight: 900;
  line-height: 30px;
  margin-bottom: 20px;
}

.sub_box_body {
  display: block;
}

.subbox_list li {
  font-size: 18px;
  font-family: 'Ubuntu', sans-serif;
  line-height: 30px;
  text-align: justify;
  color: #282828;
  font-weight: 400;
}

.sub_box_mundoDispara_3,
.sub_box_mundoDispara_4 {
  margin-bottom: 5%;
}

.sub_box_titulo {
  display: flex;
  justify-content: space-around;
  gap: 20px;
  align-items: center;
  margin: 2% 10%;
}

.sub_box_mundoDispara_1:hover,
.sub_box_mundoDispara_2:hover,
.sub_box_mundoDispara_3:hover,
.sub_box_mundoDispara_4:hover {
  transform: scale(1.05);
}

.sub_box_body {
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.5s ease-in-out, padding 0.5s ease-in-out;
}

.sub_box_body.expanded {
  max-height: 1000px;
  padding: 10px 0;
}

.expand-icon {
  display: none;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
}

.text_icon_md {
  display: flex;
  align-items: center;
  gap: 10%;
}

/* Media Queries */
@media (max-width: 768px) {
  .upperBox {
    flex-direction: column-reverse;
    width: 80%;
    justify-content: center;
    margin: 20% auto;
  }

  .boxMundoDispara1,
  .boxMundoDispara2 {
    width: 100%;
    margin: 0 auto;
    justify-content: center;
    margin-bottom: 10%;
  }

  .boxMundoDispara2 {
    text-align: center;
  }

  .boxMundoDispara3 {
    flex-direction: column;
    width: 100%;
  }

  .box_sub_box {
    flex-direction: column;
    width: 100%;
  }

  .sub_box_mundoDispara_1,
  .sub_box_mundoDispara_2,
  .sub_box_mundoDispara_3,
  .sub_box_mundoDispara_4 {
    width: 100%;
    margin: 10% 0 10% 0;
    text-align: center;
  }

  .sub_box_body.collapsed {
    max-height: 0;
    padding: 0 50px;
  }

  .sub_box_body.expanded {
    max-height: 1000px;
    padding: 10px 50px;
  }

  .expand-button {
    display: inline-block;
    background-color: white;
    border: none;
    color: #e80088;
    font-size: 24px;
  }

  .button_box {
    display: flex;
    gap: 20px;
    align-items: center;
  }

  .text_icon_md {
    display: flex;
    align-items: center;
  }
}