html,
body {
    margin: 0;
    padding: 0;
    min-height: 100%;
    display: flex;
    flex-direction: column;
}

#root {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.politicasContainer {
    width: 80%;
    margin: 2% auto;
    flex-grow: 1;
    min-height: 51.3vh;
}

.tituloPolitica {
    color: #e80088;
    font-size: 36px;
    font-family: 'Codec Cold Trial', sans-serif;
    font-weight: 900;
    line-height: 30px;
    margin-bottom: 50px;
    text-align: center;
}

.titulo2Politica {
    color: #e80088;
    font-size: 24px;
    font-family: 'Ubuntu', sans-serif;
    font-weight: 600;
    line-height: 30px;
    margin-top: 50px;
    margin-bottom: 20px;
    text-align: start;
}

.paragrafoPolitica,
.itemPolitica {
    font-size: 16px;
    font-family: 'Ubuntu', sans-serif;
    line-height: 30px;
    text-align: justify;
    color: #282828;
    font-weight: 400;
}

.termos{
    font-size: 16px;
    font-family: 'Ubuntu', sans-serif;
    line-height: 30px;
    text-align: justify;
    color: #282828;
    font-weight: 400;
    width: 80%;
    margin: 1% auto;
   
}

.tituloTermos{
    color: #e80088;
    font-size: 24px;
    font-family: 'Ubuntu', sans-serif;
    font-weight: 600;
    line-height: 30px;
    margin-top: 50px;
    margin-bottom: 20px;
    text-align: center;
}

    .paragrafo-termos {
    font-size: 16px;
    font-family: 'Ubuntu', sans-serif;
    line-height: 30px;
    text-align: justify;
    color: #282828;
    font-weight: 400;
}

.linkPolitica {
    font-family: 'Ubuntu', sans-serif;
    color: #282828;
    font-weight: bold;
    text-decoration: underline;
}

/* .itemPolitica {
    margin: 20px 0;
} */

.listaPolitica {
    list-style-position: inside;
}

.dropdownSection {
    margin-bottom: 20px;
}

.dropdownHeader {
    cursor: pointer;
    background-color: #f1f1f1;
    padding: 10px;
    border: 1px solid #ccc;
    font-family: 'Codec Cold Trial', sans-serif;
}

.dropdownContent {
    max-height: 0;
    overflow: hidden;
    transition: all 0.3s ease-out;
    padding: 0 10px;
}

.dropdownContent.expanded {
    max-height: none;
    overflow: visible;
}

.collapsed {
    height: 0;
    visibility: hidden;
}

.expanded {
    height: auto;
    visibility: visible;
}

.paragrafo-termos .paragrafoPolitica {
    font-family: 'Ubuntu', sans-serif;
}

.drop-politicas .drop-termos {
    font-family: 'Ubuntu', sans-serif;
    flex-grow: 1;
}

span .data-email {
    color: #282828;
    font-weight: bolder;
}

span .data-email:hover {
    color: #e80088;
}