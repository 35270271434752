.containerClientes {
  margin-bottom: 12%;
}

.blocoTextoClientes {
  margin: 2% 13%;
}

.containerClientes h1 {
  font-size: 36px;
  font-weight: 900;
  line-height: 47px;
  color: #E80088;
  font-family: 'Codec Cold Trial', sans-serif;
}

.containerClientes p {
  font-size: 18px;
  font-family: 'Ubuntu', sans-serif;
  line-height: 30px;
}

.image-container {
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  height: auto;
  gap: 10px;
  padding: 20px;
  width: 80%;

}

.image-item {
  width: calc(15% - 10px);
  max-width: 30%;
  height: 10em;
  overflow: hidden;
  border-radius: 10px;
  border: 2px solid #ccc;
  transition: transform 0.3s ease-in-out, background-color 0.3s ease-in-out;
  background-color: transparent;
}

.image-item:hover {
  transform: scale(1.05);
  /* Efeito de zoom ao passar o mouse */
  background-color: #E80088;
  /* Cor de fundo ao passar o mouse */
}

.image-item img {
  width: 80%;
  height: auto;
  object-fit: cover;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media (max-width: 768px) {

  .image-item {
    max-width: 100%;
    width: 90%;
  }
}

@media only screen and (min-width: 330px) and (max-width: 959px) {

  .containerClientes {
    margin-top: 15%;
  }

}