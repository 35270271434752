footer {
    width: 100%;
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    padding: 3% 10%;
    background-color: black;
    color: white;
    position: relative;
}

a {
    text-decoration: none;
    color: white;
}

a:hover {
    color: #E80088;
}

.bloco1 {
    max-width: 100%;
    z-index: 1;
    margin-top: 1.25rem;
}

.bloco2 {

    max-width: 100%;
}

.img-footer {
    max-width: 100%;
    height: auto;

}

.textoDefault {
    font-family: 'Ubuntu', sans-serif;
    font-size: 15px;
}

.textoNegrito {
    font-size: 20px;
    font-weight: 600;
    font-family: 'Ubuntu', sans-serif;
}

.whatsapp-button {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(58, 214, 116);
    width: 56px;
    height: 56px;
    border-radius: 50%;
    position: fixed;
    bottom: 62px;
    right: 27px;
    z-index: 1000;
    transition: transform 0.3s;
}

.whatsapp-button:hover {
    transform: scale(1.2);
}


@media (max-width: 768px) {
    footer {
        width: 100%;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: center;
        text-align: center;
        gap: 100px;
        padding-top: 20%;
        padding-bottom: 11%;
    }

    .bloco2 {
        padding: 7%;
    }
}

/* @media only screen and (min-width: 768px) and (max-width: 959px) {
    width: 33;
} */