/* Ajuste para o ícone de download no canto superior direito do PDF */
.pdfPageWrapper {
  position: relative;
}

.download-arrow {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  background-color: rgba(232, 0, 136, 1);
  padding: 10px;
  border-radius: 50%;
  transition: background-color 0.3s ease, opacity 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.7;
  /* Transparência inicial */
}

.download-arrow:hover {
  background-color: #e80088;
  /* Cor sólida ao passar o mouse */
  opacity: 1;
  /* Opacidade completa ao passar o mouse */
}

.download-arrow svg {
  fill: white;
  /* Cor do ícone */
  width: 24px;
  height: 24px;
}

/* Tooltip */
.tooltip {
  visibility: hidden;
  width: 80px;
  background-color: #0000AC;
  /* Cor de fundo do tooltip */
  color: #fff;
  /* Cor do texto */
  text-align: center;
  border-radius: 5px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  /* Coloca acima do ícone */
  left: 50%;
  margin-left: -40px;
  /* Centraliza o tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

.download-arrow:hover .tooltip {
  visibility: visible;
  opacity: 1;
  /* Torna visível ao passar o mouse */
}

/* Ajustes para dispositivos móveis */
@media (max-width: 768px) {
  .download-arrow {
    top: 10px;
    right: 5px;
    padding: 8px;
    opacity: 0.7;
  }

  .download-arrow svg {
    width: 20px;
    height: 20px;
  }
}