html,
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.grid-card {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  justify-content: center;
}

.card-humano {
  width: 19rem;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  margin: 5px;
  transition: max-height 0.3s ease-in-out;
}

.profile-image {
  width: 100%;
  object-fit: cover;
  border-bottom: 1px solid #e0e0e0;
}

.card-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #ededed;
  min-height: 100%;
}

.card-name {
  font-size: 16px;
  margin-top: 10px;
  font-family: 'Codec Cold Trial', sans-serif;
  text-align: center;
}

.cargo {
  font-size: 16px;
  color: #e80088;
  font-family: 'Ubuntu', sans-serif;
  text-align: center;
  font-weight: 700;
}

.linkedin-btn {
  margin-top: 10px;
}

.linkedin-btn img {
  width: 30px;
  height: 30px;
}

.saiba-mais-btn {
  margin-top: 10px;
  padding: 10px 20px;
  color: #282828;
  border: none;
  cursor: pointer;
  font-family: 'Ubuntu', sans-serif;
  font-size: 16px;
  background-color: #ededed;
  font-weight: 700;
}

.saiba-mais-btn:hover {
  color: #c6006f;
}

.bio {
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  transition: max-height 0.5s ease-in-out, opacity 0.3s ease-in-out;
  font-size: 16px;
  color: #333;
  line-height: 1.5;
  margin-top: 10px;
  font-family: 'Ubuntu', sans-serif;
}

.bio.mostrar {
  max-height: 5000px;
  opacity: 1;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 500px;
  width: 90%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  position: relative;
}

.close-modal {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #c6006f;
}

.modal-content h2 {
  font-size: 24px;
  margin-bottom: 10px;
  font-family: 'Codec Cold Trial', sans-serif;
}

.modal-content p {
  font-size: 16px;
  font-family: 'Ubuntu', sans-serif;
}

@media screen and (min-width: 768px) and (max-width: 1370px) {
  .card-humano {
    width: 15rem;
    max-height: none;
    margin: 5% auto;
    justify-content: center;
  }
}

@media (max-width: 768px) {
  .card-humano {
    width: 80%;
    max-height: none;
    margin: 5% auto;
    justify-content: center;
  }

  .profile-image {
    border-bottom: none;
  }

  .linkedin-btn {
    margin-top: 10px;
  }

  .saiba-mais-btn {
    width: 80%;
  }
}