.containerMundoAtende {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 3%;
}

.box1mundoAtende {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 35%;
  margin-bottom: 5%;
}

.box1mundoAtende h1 {
  color: #e80088;
  font-size: 36px;
  font-family: 'Codec Cold Trial', sans-serif;
  font-weight: 900;
  line-height: 30px;
}

.box1mundoAtende h2 {
  color: #282828;
  font-size: 24px;
  font-family: 'Ubuntu', sans-serif;
  font-weight: 600;
  line-height: 30px;
}

.box1mundoAtende p {
  font-size: 18px;
  font-family: 'Ubuntu', sans-serif;
  line-height: 30px;
  text-align: justify;
  color: #282828;
  font-weight: 400;
}

.box2mundoAtende {
  width: 35%;
  margin-left: 5%;
  margin-bottom: 5%;
}

.imagemMundoAtende {
  width: 100%;
}

@media (max-width: 768px) {
  .containerMundoAtende {
    width: 80%;
    flex-direction: column-reverse;
    margin: 5% auto;
  }

  .box1mundoAtende,
  .box2mundoAtende {
    width: 100%;
    justify-content: center;
    text-align: justify;
  }

  .box2mundoAtende {
    width: 70%;
    margin-bottom: 20%;
  }

}

@media only screen and (min-width: 330px) and (max-width: 959px) {

  .containerMundoAtende {
    margin-top: 12%;

  }
}