.pdfContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 3% auto;
  max-width: 100%;
  /* Garante que o PDF não ultrapasse o container */
  margin-bottom: 20px;
}

.pdfPage {
  max-width: 800px;
  /* Define a largura máxima para o PDF em telas grandes */
  width: 90%;
  /* Ocupa 90% da largura da tela, até o máximo de 800px */
}

.pagination {
  display: flex;
  justify-content: center;
  /* Centraliza horizontalmente */
  align-items: center;
  /* Alinha verticalmente no centro */
  margin-top: 20px;
  /* Adiciona uma margem superior, se necessário */
}

.pagination p {
  margin: 0 10px;
  /* Adiciona uma margem lateral ao texto */
  font-size: 1em;
  /* Tamanho padrão do texto */
}

.pagination button {
  background-color: #e80088;
  color: white;
  border: none;
  padding: 10px 20px;
  margin: 5px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1em;
  font-family: "Ubuntu", sans-serif;
}

.pagination button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.pagination button:hover {
  background-color: #c70070;
}



/* CSS para a transição suave */
.pdfPageWrapper {
  transition: opacity 0.3s ease-in-out;
}

.fade-in {
  opacity: 1;
}

.fade-out {
  opacity: 0;
}

.pdfPageWrapper {
  position: relative;
  transition: opacity 0.3s ease-in-out;
}

.fade-in {
  opacity: 1;
}

.fade-out {
  opacity: 0;
}


@media (max-width: 768px) {
  .pdfContainer {
    width: 100%;
    padding: 0;
  }

  .pdfPage {
    width: 100%;
    /* Para dispositivos menores, o PDF ocupará 100% da largura disponível */
  }

  .pagination p {
    font-size: 14px;
  }

  .download-button button {
    padding: 8px 16px;
    font-size: 0.9em;
    /* Ajuste o tamanho do texto do botão */
    border-radius: 15px;
    /* Torna o botão mais compacto no mobile */
  }

  .download-arrow {
    top: 10px;
    /* Ajuste para dispositivos móveis */
    right: 5px;
    padding: 8px;
    opacity: 0.5;
    /* Transparência inicial no mobile */
  }

  .download-arrow svg {
    width: 20px;
    height: 20px;
  }
}

/* Media query para telas menores */
@media (max-width: 480px) {
  .pagination p {
    font-size: 0.8em;
    /* Reduz o tamanho do texto */
  }

  .pagination button {
    padding: 8px 16px;
    /* Reduz o padding dos botões */
    font-size: 0.8em;
    /* Reduz o tamanho do texto dos botões */
  }

  .download-button button {
    padding: 6px 12px;
    /* Reduz o padding no mobile */
    font-size: 0.8em;
    /* Reduz o tamanho do texto */
    border-radius: 10px;
    /* Ajuste adicional para telas pequenas */
  }
}

@media only screen and (min-width: 330px) and (max-width: 959px) {

  .pdfContainer {
    margin-top: 15%;

  }
}