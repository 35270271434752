.upContainer {
    display: flex;
    align-items: center;
    margin: 3% 10%;
}

.paragrafo1 {
    width: 45%;
    margin-right: 5%;

}

.paragrafo1 h1 {
    font-size: 36px;
    font-weight: bold;
    line-height: 47px;
}

.paragrafo1 h1 strong {
    color: #E80088;
    font-family: 'Codec Cold Trial', sans-serif;
    font-weight: 900;
}

.paragrafo1 p {
    font-size: 18px;
    font-family: 'Ubuntu', sans-serif;
    line-height: 30px;
}

.imagemMulher-sobrenos {
    width: 45%;
    margin-left: 5%;
}

.paragrafo2 {
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 45%;
    margin-right: 5%;
}

.paragrafo2 p {
    font-size: 18px;
    font-family: 'Ubuntu', sans-serif;
    line-height: 30px;
}

.paragrafo2 p bold {
    color: blue;
}

.downContainer {
    display: flex;
    align-items: center;
    margin: 0 10%;
    margin-bottom: 5%;
}

.imagem-sobrenos {
    width: 45%;
    margin-right: 5%;
}

.italico {
    font-style: italic;
}

@media (max-width: 768px) {

    .container {
        display: flex;
        flex-direction: column;
        margin-top: 10%;
        justify-content: center;
        margin: 5% auto 0 auto;
    }

    .upContainer,
    .downContainer {
        display: flex;
        flex-direction: column;
        width: 90%;
        margin: 15% auto;

        img {
            width: 90%;
            margin: 0 auto;
        }
    }

    .paragrafo1 {
        width: 90%;
    }

    .paragrafo2 {
        text-align: center;
        justify-content: center;
        margin: 5%;
    }

    .imagem-sobrenos {
        display: none;
    }

}

@media only screen and (min-width: 330px) and (max-width: 959px) {

    .container {
        margin-top: 10%;
    }

}