.sessao1 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
        "box1 box3"
        "box2 box3"
        "box2 box4";
    width: 100%;
    max-width: 1600px;
    margin: 0 auto;
    overflow: hidden;
}

.box1 {
    grid-area: box1;
    width: 90%;
    margin-top: 5%;
}

.box2 {
    grid-area: box2;
    width: 90%;
}

.box3 {
    grid-area: box3;
    margin: 5%;
}

.box3 img {
    max-width: 100%;
    height: auto;
    display: block;
    margin: 0 auto;
}

.box4 {
    grid-area: box4;
    width: 90%;
    padding: 0;
}

.sessao3 {
    width: 100%;
    max-width: 1600px;
    margin: 5% auto;

}

.list-titulo {
    font-family: 'Ubuntu', sans-serif;
    font-size: 20px;
}

.humanosTitulo {
    color: #e80088;
    font-size: 36px;
    font-family: 'Codec Cold Trial', sans-serif;
    font-weight: 900;
    line-height: 30px;
    margin-bottom: 20px;
}

.humanosParagrafo1 {
    font-size: 18px;
    font-family: 'Ubuntu', sans-serif;
    line-height: 30px;
    text-align: justify;
    color: #282828;
    font-weight: 400;

}

.list-beliefs h2 {
    font-family: 'Codec Cold Trial', sans-serif;
    margin-top: 30px;
}

.list-beliefs p {
    font-family: 'Ubuntu', sans-serif;
    font-weight: 400;
    line-height: 30px;
}


.humanos-subtitulo {
    color: #e80088;
    font-family: 'Code Cold Trial', sans-serif;
    font-weight: 900;
    line-height: 30px;
    text-align: center;
    font-size: 26px;
    margin-bottom: 5%;
}


@media (max-width: 1467px) {
    .sessao1 {
        grid-template-columns: 1fr 1fr;
        grid-template-areas:
            "box1 box3"
            "box2 box3"
            "box2 box4"

        ;
        /* width: 100%; */
        justify-items: center;
        max-width: 1300px;
    }

    .box3 img {
        width: 100%;
    }

    .sessao3 {
        max-width: 1300px;
        justify-items: center;
    }
}

@media (max-width: 1467px) {
    .sessao1 {
        grid-template-columns: 1fr 1fr;
        grid-template-areas:
            "box1 box3"
            "box2 box3"
            "box2 box4"
            "box2 box4"

        ;
        width: 100%;
        justify-items: center;
    }
}

@media (max-width: 1021px) {
    .sessao1 {
        grid-template-columns: 1fr;
        grid-template-areas:
            "box3"
            "box1"
            "box2"
            "box4"
        ;
        width: 100%;
        justify-items: center;


    }

    .box3 img {
        width: 80%;
        margin: 0 auto;
    }

    .sessao3 {
        justify-items: center;
    }
}

/* Estilos para mobile */
@media (max-width: 768px) {
    .humanosContainer {
        margin: 0 auto;
    }

    .sessao1 {
        grid-template-columns: 1fr;
        grid-template-areas:
            "box3"
            "box1"
            "box2"
            "box4"
        ;
        justify-items: center;

    }


    .box2 {
        margin-top: 20px;
    }

    .box3 img {
        width: 100%;
        height: auto;
    }

    .list-beliefs {
        margin-top: 10px;
    }

    .sessao2 {
        flex-direction: column;
        align-items: center;
        width: 100%;

    }

    .sessao3 {
        width: 100%;
        justify-items: center;
    }

    .humanos-subtitulo {
        justify-items: center;
        font-size: 20px;
    }

    .humanosTitulo {
        font-size: 28px;

    }

    .humanosParagrafo1 {
        font-size: 16px;
        line-height: 24px;

    }
}